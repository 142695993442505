import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { EndComponent } from './pages/end/end.component';
import { MeetingComponent } from './pages/meeting/meeting.component';

const routes: Routes = [
  { path: '', component: MeetingComponent },
  { path: 'end', component: EndComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
