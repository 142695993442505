import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { PostMessageKey } from 'src/app/models/post-message-key.enum';

@Component({
  selector: 'app-end',
  templateUrl: './end.component.html',
  styleUrls: ['./end.component.scss']
})
export class EndComponent implements OnInit {

  constructor(
    private activatedRoute: ActivatedRoute
  ) { }

  ngOnInit(): void {
    this.activatedRoute.queryParams.subscribe(params => {
      parent.postMessage({ status: PostMessageKey.WEBINAR_ENDED }, params.origin);
      
      if (params.url && params.origin) {
        parent.postMessage({ url: params.url }, params.origin);
      }
    });
  }

}
