import { Component, HostListener, NgZone, OnInit } from '@angular/core';

import { ZoomMtg } from '@zoom/meetingsdk';
import { PostMessageKey } from 'src/app/models/post-message-key.enum';


ZoomMtg.preLoadWasm();
ZoomMtg.prepareWebSDK();

@Component({
  selector: 'app-meeting',
  templateUrl: './meeting.component.html',
  styleUrls: ['./meeting.component.scss']
})
export class MeetingComponent implements OnInit {

  @HostListener('window:message', ['$event']) onMessage(e): void {
    if (e.data && e.data.signature) {
      this.startMeeting(
        e.data.signature,
        e.data.meetingNumber,
        e.data.userName,
        e.data.userEmail,
        e.data.sdkKey,
        e.data.passWord,
        e.data.leaveUrl,
        e.data.origin
      );
    }
  }

  constructor(private ngZone: NgZone) { }

  ngOnInit(): void {
  }

  private startMeeting(
    signature: string,
    meetingNumber: number,
    userName: string,
    userEmail: string,
    sdkKey: string,
    passWord: string,
    leaveUrl: string,
    origin: string
  ): void {
    document.getElementById('zmmtg-root').style.display = 'block'

    this.ngZone.runOutsideAngular(() => {
      ZoomMtg.init({
        leaveUrl: `${window.location.origin}/end?url=${leaveUrl}&origin=${origin}`,
        isSupportAV: true,
        success: () => {
          ZoomMtg.join({
            signature,
            meetingNumber,
            userName,
            userEmail,
            sdkKey,
            passWord,
            success: () => {
              parent.postMessage({ status: PostMessageKey.WEBINAR_STARTED }, origin);
            },
            error: () => {}
          });
        }
      });
    })

  }
}
